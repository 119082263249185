import { Typography, Box, SxProps } from '@mui/material';
import { FC, ReactNode } from 'react';

import ErrorIcon from 'assets/icons/ErrorIcon';
import { BannerType } from 'constants/enums';

import { IconWrapper, BannerWrapper } from './index.styled';

interface BannerMessageProps {
  children?: ReactNode;
  message: string | ReactNode;
  sx?: SxProps;
  bannerType?: BannerType;
}

const BannerMessage: FC<BannerMessageProps> = ({
  message,
  children,
  sx = {},
  bannerType = BannerType.warning,
}) => (
  <BannerWrapper sx={sx} bannerType={bannerType}>
    <Box display="flex" alignItems="flex-start">
      <IconWrapper bannerType={bannerType}>
        <ErrorIcon />
      </IconWrapper>

      {message && (
        <Typography variant="subtitle1">
          {message}
        </Typography>
      )}
    </Box>

    {children}
  </BannerWrapper>
);

export default BannerMessage;
