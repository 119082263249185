import {
  format, getWeekOfMonth, getWeeksInMonth, addBusinessDays,
} from 'date-fns';
import { isNil } from 'lodash';
import { NumberFormatValues } from 'react-number-format';

import {
  CreateTransactionState,
  FromDetailsState,
  ToFinancialAccount,
} from 'store/create-transaction/createTransactionSlice';

import {
  AmountType,
  BankAccountCategory,
  CurrencyType,
  CustomMonthlyRecurrence,
  FaAccountType, FinancialAccountType,
  TransactionRecurrence,
  TransactionSettlementPriority,
  TransactionSolution,
} from 'constants/enums';
import { MAX_TRANSACTION_AMOUNT } from 'constants/transaction';
import { BankAccount } from 'models/bankAccount.interface';
import { FaAccountTransaction } from 'models/faTransaction.interface';
import { Recipient } from 'models/recipient.interface';
import { CustomRecurrence } from 'models/recurrence.interface';
import { ScheduleTime } from 'models/scheduleTime.interface';
import { CreateTransactionPayload } from 'models/transaction.interface';
import { isInternalAccount } from 'utils/bankAccount';
import { formatScheduleTime, formatUserName } from 'utils/formatters';
import { extractNumberFromNumericFormat } from 'utils/numbers';

export const getPredefinedInformation = (from?: BankAccount, to?: ToFinancialAccount) => {
  const toCategory = to && 'category' in to ? to.category : null;

  if (from?.category === BankAccountCategory.external && toCategory === BankAccountCategory.external) {
    return {
      fromDetails: {
        achEntryType: 'CCD',
        isAchEntryTypeDisabled: true,
        paymentReason: 'funds_transfer',
        isPaymentReasonDisabled: true,
      },
    };
  }

  if (to && !toCategory) {
    return {
      fromDetails: {
        paymentReason: 'bill-payment',
        isPaymentReasonDisabled: true,
      },
    };
  }

  return {
    fromDetails: {
      isAchEntryTypeDisabled: false,
      paymentReason: '',
      isPaymentReasonDisabled: false,
    },
  };
};

export const isMLT = (from: BankAccount, to: BankAccount | ToFinancialAccount) => {
  const toCategory = to && 'category' in to ? to.category : null;

  return (
    from.category === BankAccountCategory.external && toCategory === BankAccountCategory.external
  );
};

export const hasAllTransactionDetails = (
  from: BankAccount,
  to: ToFinancialAccount,
  fromDetails: FromDetailsState,
) => {
  const toCategory = to && 'category' in to ? to.category : null;

  if (from.category === BankAccountCategory.external && isInternalAccount(toCategory)) {
    return fromDetails.solution
      && fromDetails.achEntryType
      && fromDetails.paymentReason
      && fromDetails.settlementPriority;
  }

  if (isInternalAccount(from.category) && toCategory === BankAccountCategory.external) {
    const requiredInformation = fromDetails.solution && fromDetails.paymentReason && fromDetails.settlementPriority;

    return fromDetails.solution === TransactionSolution.ach
      ? requiredInformation && fromDetails.achEntryType
      : requiredInformation;
  }

  if (isInternalAccount(from.category) && isInternalAccount(toCategory)) {
    return fromDetails.solution
      && [TransactionSolution.transfer].includes(fromDetails.solution)
      && fromDetails.settlementPriority;
  }

  const requiredToInformation = fromDetails.paymentReason && fromDetails.settlementPriority;

  return fromDetails.solution === TransactionSolution.ach
    ? fromDetails.settlementPriority && requiredToInformation && fromDetails.achEntryType
    : fromDetails.settlementPriority && requiredToInformation;
};

export const isScheduledCompleted = (transaction: CreateTransactionState) => (
  Boolean(
    transaction.scheduleTime.name
    && transaction.scheduleTime.date
    && transaction.scheduleTime.hour
    && transaction.scheduleTime.minutes,
  )
);

export const getRecurrenceRule = (scheduleTime: ScheduleTime, customRecurrence: CustomRecurrence): string => {
  const { recurrence, date } = scheduleTime;
  const scheduleDate = !isNil(date) ? new Date(date) : new Date();
  const dayOfWeekValue = format(scheduleDate, 'EEEEEE').toUpperCase();
  const weekValue = getWeekOfMonth(scheduleDate);
  const week = weekValue === getWeeksInMonth(scheduleDate) ? '-1' : weekValue;
  const dayValue = format(scheduleDate, 'd');
  const monthValue = format(scheduleDate, 'M');

  switch (recurrence) {
    case TransactionRecurrence.daily:
      return 'FREQ=DAILY;INTERVAL=1';
    case TransactionRecurrence.weekly:
      return `FREQ=WEEKLY;INTERVAL=1;BYDAY=${dayOfWeekValue}`;
    case TransactionRecurrence.monthly:
      return `FREQ=MONTHLY;INTERVAL=1;BYSETPOS=${week};BYDAY=${dayOfWeekValue}`;
    case TransactionRecurrence.yearly:
      return `FREQ=YEARLY;INTERVAL=1;BYMONTH=${monthValue};BYMONTHDAY=${dayValue}`;
    case TransactionRecurrence.everyWeekday:
      return 'FREQ=WEEKLY;INTERVAL=1;BYDAY=MO,TU,WE,TH,FR';
    case TransactionRecurrence.custom:
    case TransactionRecurrence.customCreated: {
      const {
        count,
        repeatPeriod,
        repeatPeriodMonthlyType,
        endDate,
        occurrences,
      } = customRecurrence;
      let rule = `FREQ=${repeatPeriod};`;

      if (count) {
        rule = `${rule}INTERVAL=${count};`;
      }

      if (repeatPeriodMonthlyType === CustomMonthlyRecurrence.monthlyOnCurrentDay) {
        rule = `${rule}BYMONTHDAY=${dayValue};`;
      }

      if (repeatPeriodMonthlyType === CustomMonthlyRecurrence.monthlyOnTheLastWeek) {
        rule = `${rule}BYSETPOS=-1;BYDAY=${dayOfWeekValue};`;
      }

      if (repeatPeriodMonthlyType === CustomMonthlyRecurrence.monthlyOnCurrentWeek) {
        rule = `${rule}BYSETPOS=${week};BYDAY=${dayOfWeekValue};`;
      }

      if (occurrences) {
        return `${rule}COUNT=${occurrences};`;
      }

      if (endDate) {
        const formattedDate = `${format(endDate, 'yyyyMMdd')}T235959Z`;
        return `${rule}UNTIL=${formattedDate};`;
      }

      return rule;
    }
    default:
      return '';
  }
};

export const formatTransactionForApi = (transaction: CreateTransactionState): CreateTransactionPayload => {
  const {
    amount,
    amountType,
    direction,
    to,
    from,
    fromDetails,
    hasScheduleTime,
    scheduleTime,
    customRecurrence,
    memo,
    description,
    note,
  } = transaction;
  const creditFinancialAccountId = to && 'korFinancialAccountId' in to
    ? to.korFinancialAccountId
    : to?.financialAccount?.id;

  const newTransaction: any = {
    transactionType: direction,
    debitFinancialAccountId: from?.korFinancialAccountId,
    creditFinancialAccountId,
    solution: fromDetails.solution,
    paymentReasonId: fromDetails.paymentReason,
  };

  if (description) {
    newTransaction.description = description;
  }

  if (memo) {
    newTransaction.memo = memo;
  }

  if (note) {
    newTransaction.note = note;
  }

  if (amountType === AmountType.percentage) {
    newTransaction.debitBalancePercent = extractNumberFromNumericFormat(amount) / 100;
  } else {
    newTransaction.amount = extractNumberFromNumericFormat(amount);
    newTransaction.currency = CurrencyType.usd;
    newTransaction.settlementPriority = fromDetails.settlementPriority;
  }

  if (fromDetails.solution === TransactionSolution.ach) {
    newTransaction.metadata = {
      rkorACHEntryType: fromDetails.achEntryType,
      rkorACHIndividualId: fromDetails.individualId || null,
      rkorACHCheckSerialNumber: fromDetails.checkSerialNumber || null,
      rkorACHTerminalCity: fromDetails.terminalCity || null,
      rkorACHTerminalState: fromDetails.terminalState || null,
    };
  }

  if (hasScheduleTime) {
    newTransaction.name = scheduleTime.name;
    newTransaction.startDateTime = formatScheduleTime(scheduleTime)?.toISOString().slice(0, -1);
    newTransaction.recurrenceRule = getRecurrenceRule(scheduleTime, customRecurrence);
  }

  if (!newTransaction.recurrenceRule) {
    delete newTransaction.recurrenceRule;
  }

  return newTransaction;
};

export const getTransferEstimates = (type: TransactionSettlementPriority, date?: number | null) => {
  const formattedDate = date ? new Date(date) : new Date();

  if (type === TransactionSettlementPriority.nextDay) {
    return addBusinessDays(formattedDate, 3);
  }

  return addBusinessDays(formattedDate, 1);
};

export const convertTransactionFa = (account: FaAccountTransaction) => {
  if (account.type === FaAccountType.recipient) {
    return {
      recipient: {
        id: '',
        firstName: account.bankAccountName,
      },
      financialAccount: {
        id: account.id,
        accountType: account.bankAccountType,
        ...(account.faType === FinancialAccountType.card
          ? { cardNumberTail: account.accountNumber }
          : { accountNumberTail: account.accountNumber, bankName: account.bankAccountName }
        ),
      },
    } as unknown as Recipient;
  }
  return convertTransactionFaToAccount(account);
};

export const convertTransactionFaToAccount = (account: FaAccountTransaction) => (
  {
    id: account.id,
    balance: account.balance,
    nickname: account.nickname,
    institution: account.institution,
    currencyCode: null,
    accountNumber: account.accountNumber,
    name: account.bankAccountName,
    type: account.bankAccountType,
    address: undefined,
    category: account.bankAccountCategory,
    korFinancialAccountId: account.korFinancialAccountId,
  } as BankAccount
);

export const convertBankAccount = (account: BankAccount | ToFinancialAccount): FaAccountTransaction => {
  if ('recipient' in account) {
    return {
      id: account.financialAccount.id,
      type: FaAccountType.recipient,
      balance: undefined,
      nickname: undefined,
      institution: undefined,
      currencyCode: null,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      accountNumber: account.financialAccount.cardNumberTail || account.financialAccount.accountNumberTail,
      bankAccountName: account.recipient.businessName
        || formatUserName({ firstName: account.recipient.firstName, lastName: account.recipient.lastName }),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      bankAccountType: account.financialAccount.accountType,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      faType: account.financialAccount.cardNumber ? FinancialAccountType.card : FinancialAccountType.bank,
    };
  }

  return {
    id: account.id,
    type: FaAccountType.bankAccount,
    balance: account.balance,
    nickname: account.nickname,
    institution: account.institution,
    currencyCode: null,
    accountNumber: account.accountNumber,
    bankAccountName: account.name,
    bankAccountType: account.type,
    bankAccountCategory: account.category,
    korFinancialAccountId: account.korFinancialAccountId,
  };
};

export const isAllowedValue = (values: NumberFormatValues, additionalAmountType?: AmountType) => {
  const { value } = values;
  const numericValue = value.replace(/\D/g, '');
  const number = parseFloat(numericValue) / 100;
  const finalValue = number.toFixed(2);

  if (+finalValue > MAX_TRANSACTION_AMOUNT || +finalValue < 0) {
    return false;
  }

  return !(additionalAmountType === AmountType.percentage && finalValue && +finalValue > 100);
};
