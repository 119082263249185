import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getHours, getMinutes, isPast } from 'date-fns';
import { isNil } from 'lodash';

import { getWorkspaceBankAccounts } from 'services/BankAccountService';
import {
  createNewTransaction,
  getPaymentReasons,
  updateScheduledTransaction,
  updateTransaction,
} from 'services/TransactionService';
import { resetStore } from 'store/actions';
import { RootState } from 'store/index';

import {
  AmountType,
  AmPm,
  ApiStatus,
  CustomMonthlyRecurrence,
  RecurrenceEndPeriod,
  TransactionDirection,
  TransactionRecurrence,
  TransactionSolution,
} from 'constants/enums';
import { BankAccount } from 'models/bankAccount.interface';
import { PaymentReasonInterface } from 'models/paymentReason.interface';
import { Recipient } from 'models/recipient.interface';
import { CustomRecurrence } from 'models/recurrence.interface';
import { ScheduleTime } from 'models/scheduleTime.interface';
import { CreateTransactionPayload, ScheduledTransaction } from 'models/transaction.interface';
import { isInternalAccount } from 'utils/bankAccount';
import {
  convertTransactionFa,
  formatTransactionForApi,
  getPredefinedInformation,
  isMLT,
} from 'utils/createTransaction';
import { handleApiErrors } from 'utils/errorUtils';
import { formatScheduleTime } from 'utils/formatters';
import { getRecurrenceCustomRuleForForm, getRecurrenceRuleForForm } from 'utils/scheduleTime';

export interface FromDetailsState {
  solution?: TransactionSolution;
  paymentReason: string;
  settlementPriority?: string;
  achEntryType?: string;
  isAchEntryTypeDisabled?: boolean;
  isPaymentReasonDisabled?: boolean;
  individualId?: string;
  terminalState?: string;
  terminalCity?: string;
  checkSerialNumber?: string;
}

export interface ToDetailsState {
  solution?: TransactionSolution;
  paymentReason: string;
  settlementPriority?: string;
  isAchEntryTypeDisabled?: boolean;
  achEntryType?: string;
  individualId?: string;
  terminalState?: string;
  terminalCity?: string;
  checkSerialNumber?: string;
}

export type ToFinancialAccount = BankAccount | Recipient;

export interface CreateTransactionState {
  amount: string;
  amountType?: AmountType;
  direction?: TransactionDirection;
  from?: BankAccount;
  to?: ToFinancialAccount;
  fromDetails: FromDetailsState;
  toDetails: ToDetailsState;
  note?: string;
  scheduleTime: ScheduleTime;
  fundsReturnDate?: number;
  showReturnFunds: boolean;
  showAdditionalAmount: boolean;
  additionalAmountType?: AmountType;
  additionalAmount?: string;
  customRecurrence: CustomRecurrence;
  customRecurrenceErrors: Record<string, string>;
  hasScheduleTime: boolean;
  bankAccounts: {
    data: BankAccount[];
    status: ApiStatus;
  };
  fieldErrors: Record<string, string>;
  reasons: {
    data: PaymentReasonInterface[];
    status: ApiStatus;
  };
  memo?: string;
  description?: string;
  status: ApiStatus;
  transactionToEdit?: ScheduledTransaction;
}

export const initialState: CreateTransactionState = {
  direction: undefined,
  amount: '',
  amountType: AmountType.exactAmount,
  from: undefined,
  to: undefined,
  customRecurrenceErrors: {},
  fromDetails: {
    solution: undefined,
    paymentReason: '',
    settlementPriority: '',
    achEntryType: 'CCD',
    isAchEntryTypeDisabled: false,
    isPaymentReasonDisabled: false,
    individualId: '',
    terminalState: '',
    terminalCity: '',
    checkSerialNumber: '',
  },
  toDetails: {
    solution: undefined,
    paymentReason: '',
    settlementPriority: '',
    achEntryType: '',
  },
  note: '',
  hasScheduleTime: false,
  showReturnFunds: false,
  showAdditionalAmount: false,
  fundsReturnDate: undefined,
  additionalAmountType: AmountType.percentage,
  additionalAmount: '',
  scheduleTime: {
    name: '',
    date: new Date().getTime(),
    hour: '',
    minutes: '',
    ampm: AmPm.am,
    recurrence: TransactionRecurrence.doesNotRepeat,
  },
  customRecurrence: {
    count: 1,
    repeatPeriod: TransactionRecurrence.weekly,
    repeatPeriodMonthlyType: CustomMonthlyRecurrence.monthlyOnCurrentDay,
    endsPeriod: RecurrenceEndPeriod.never,
    occurrences: 0,
    endDate: null,
  },
  fieldErrors: {},
  bankAccounts: {
    data: [],
    status: ApiStatus.idle,
  },
  reasons: {
    data: [],
    status: ApiStatus.idle,
  },
  memo: '',
  description: '',
  status: ApiStatus.idle,
  transactionToEdit: undefined,
};

export const fetchBankAccounts = createAsyncThunk(
  'createTransaction/fetchBankAccounts',
  async (workspaceId: string, thunkAPI) => {
    try {
      const { data } = await getWorkspaceBankAccounts(workspaceId);
      return data;
    } catch (e) {
      handleApiErrors(e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const fetchPaymentReasons = createAsyncThunk(
  'createTransaction/paymentReasons',
  async (_, thunkAPI) => {
    try {
      const { data } = await getPaymentReasons();
      return data;
    } catch (e) {
      handleApiErrors(e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const createTransaction = createAsyncThunk(
  'createTransaction/createTransaction',
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const transaction: CreateTransactionPayload = formatTransactionForApi(state.createTransaction);
      const { data } = await createNewTransaction(transaction);
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const onUpdateTransaction = createAsyncThunk(
  'createTransaction/updateTransaction',
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const { transactionToEdit } = state.createTransaction;

      if (transactionToEdit?.id) {
        const payload = {
          ...transactionToEdit,
          ...formatTransactionForApi(state.createTransaction),
        } as CreateTransactionPayload;

        const fn = payload.startDateTime ? updateScheduledTransaction : updateTransaction;
        const { data } = await fn(payload, transactionToEdit.id, transactionToEdit.type);
        return data;
      }

      const error = {
        message: 'transactionId.missing',
        detail: 'Transaction id missing',
      };
      throw error;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const createTransactionSlice = createSlice({
  name: 'createTransaction',
  initialState,
  reducers: {
    setDirection: (state, action: PayloadAction<TransactionDirection>) => {
      state.direction = action.payload;
      return state;
    },
    setShowReturnFunds: (state, action: PayloadAction<boolean>) => {
      state.showReturnFunds = action.payload;

      if (!action.payload) {
        state.fundsReturnDate = undefined;
        state.showAdditionalAmount = false;
        state.additionalAmount = '';
        state.additionalAmountType = AmountType.percentage;
      }

      return state;
    },
    setShowAdditionalAmount: (state, action: PayloadAction<boolean>) => {
      state.showAdditionalAmount = action.payload;

      if (!action.payload) {
        state.additionalAmount = '';
        state.additionalAmountType = AmountType.percentage;
      }

      return state;
    },
    setFundsReturnDate: (state, action: PayloadAction<number|undefined>) => {
      state.fundsReturnDate = action.payload;
      return state;
    },
    setAdditionalAmountType: (state, action: PayloadAction<AmountType>) => {
      state.additionalAmountType = action.payload;
      state.additionalAmount = '';
      return state;
    },
    setAdditionalAmount: (state, action: PayloadAction<string>) => {
      state.additionalAmount = action.payload;
      return state;
    },
    setScheduleTime: (state, action: PayloadAction<ScheduleTime>) => {
      state.scheduleTime = action.payload;

      const finalDate = formatScheduleTime(action.payload);
      const hasDateError = !!state.fieldErrors?.scheduleDate;

      if (!isNil(finalDate) && isPast(new Date(finalDate))) {
        state.fieldErrors = {
          ...state.fieldErrors,
          scheduleDate: 'error.dateIsInThePast',
        };
      } else if (hasDateError) {
        state.fieldErrors = {
          ...state.fieldErrors,
          scheduleDate: '',
        };
      }

      return state;
    },
    setFromDetails: (state, action: PayloadAction<Record<string, string>>) => {
      state.fromDetails = {
        ...state.fromDetails,
        ...action.payload,
      };
      return state;
    },
    setToDetails: (state, action: PayloadAction<Record<string, string>>) => {
      state.toDetails = {
        ...state.toDetails,
        ...action.payload,
      };
      return state;
    },
    setFieldErrors: (state, action: PayloadAction<Record<string, string>>) => {
      state.fieldErrors = action.payload;
      return state;
    },
    setCustomRecurrence: (state, action: PayloadAction<CustomRecurrence>) => {
      state.customRecurrence = action.payload;
      return state;
    },
    setCustomRecurrenceErrors: (state, action: PayloadAction<Record<string, string>>) => {
      state.customRecurrenceErrors = action.payload;
      return state;
    },
    setHasScheduleTime: (state, action: PayloadAction<boolean>) => {
      state.hasScheduleTime = action.payload;
      return state;
    },
    setAmount: (state, action: PayloadAction<string>) => {
      state.amount = action.payload;
      return state;
    },
    setAmountType: (state, action: PayloadAction<AmountType>) => {
      state.amountType = action.payload;
      return state;
    },
    setFromBankAccount: (state, action: PayloadAction<BankAccount|undefined>) => {
      //  reset previous state if from account change
      if (state.to && state.from?.category !== action.payload?.category) {
        state.toDetails = initialState.toDetails;
        state.fromDetails = initialState.fromDetails;
      } else {
        const predefinedInfo = getPredefinedInformation(action.payload, state.to);
        state.fromDetails = {
          ...state.fromDetails,
          ...predefinedInfo.fromDetails,
        };
      }

      state.from = action.payload;

      if (isInternalAccount(action.payload?.category)) {
        state.direction = TransactionDirection.send;
      } else {
        state.direction = TransactionDirection.request;
      }

      return state;
    },
    setToBankAccount: (state, action: PayloadAction<BankAccount|undefined>) => {
      const predefinedInfo = getPredefinedInformation(state.from, action.payload);
      state.fromDetails = {
        ...state.fromDetails,
        ...predefinedInfo.fromDetails,
      };
      state.to = action.payload;

      if (state.from && action.payload && isMLT(state.from, action.payload)) {
        state.toDetails = {
          ...state.toDetails,
          solution: TransactionSolution.ach,
        };
      }

      return state;
    },
    setNotes: (state, action: PayloadAction<string>) => {
      state.note = action.payload;
      return state;
    },
    initScheduledEditMode: (state, action:PayloadAction<ScheduledTransaction>) => {
      let newState = {
        ...state,
        direction: action.payload.transactionType,
        solution: action.payload.solution,
        from: convertTransactionFa(action.payload.transactionFaFrom),
        fromDetails: {
          solution: action.payload.solution,
          paymentReason: action.payload.paymentReasonId,
          settlementPriority: action.payload.settlementPriority,
          achEntryType: action.payload.metadata?.rkorACHEntryType,
          individualId: action.payload.metadata?.rkorACHIndividualId || null,
          checkSerialNumber: action.payload.metadata?.rkorACHCheckSerialNumber || null,
          terminalCity: action.payload.metadata?.rkorACHTerminalCity || null,
          terminalState: action.payload.metadata?.rkorACHTerminalState || null,
        },
        to: convertTransactionFa(action.payload.transactionFaTo),
        amount: action.payload.debitBalancePercent
          ? (action.payload.debitBalancePercent * 100).toString()
          : action.payload.amount.toString(),
        hasScheduleTime: !!action.payload.startDateTime,
        transactionToEdit: action.payload,
        memo: action.payload.memo || '',
        description: action.payload.description || '',
        note: action.payload.note || '',
      };

      if (action.payload.startDateTime) {
        const scheduleTime = new Date(`${action.payload.startDateTime}Z`).getTime();
        const hours = getHours(scheduleTime);
        const minutes = getMinutes(scheduleTime);
        const recurrence = action.payload.recurrenceRule
          ? getRecurrenceRuleForForm(action.payload.startDateTime, action.payload.recurrenceRule)
          : TransactionRecurrence.doesNotRepeat;

        newState = {
          ...newState,
          scheduleTime: {
            name: action.payload.name || '',
            date: scheduleTime,
            hour: hours > 12 ? (hours - 12).toString() : hours.toString(),
            minutes: minutes < 10 ? `0${minutes}` : minutes.toString(),
            ampm: hours > 12 ? AmPm.pm : AmPm.am,
            recurrence,
          },
          customRecurrence: action.payload.recurrenceRule && recurrence === TransactionRecurrence.customCreated
            ? getRecurrenceCustomRuleForForm(action.payload.recurrenceRule)
            : initialState.customRecurrence,
        };
      }

      return newState as CreateTransactionState;
    },
    setMemo: (state, action: PayloadAction<string>) => {
      state.memo = action.payload;
      return state;
    },
    setDescription: (state, action: PayloadAction<string>) => {
      state.description = action.payload;
      return state;
    },
    resetTransactionState: (state, action: PayloadAction<BankAccount|null>) => ({
      ...initialState,
      from: action.payload ? action.payload : undefined,
      bankAccounts: state.bankAccounts,
      reasons: state.reasons,
    }),
    resetCustomRecurrence: (state) => ({
      ...state,
      customRecurrence: initialState.customRecurrence,
    }),
    resetState: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(resetStore, () => initialState)
      .addCase(fetchBankAccounts.pending, (state) => {
        state.bankAccounts.status = ApiStatus.loading;
      })
      .addCase(fetchBankAccounts.fulfilled, (state, action) => {
        state.bankAccounts = {
          data: action.payload,
          status: ApiStatus.idle,
        };
      })
      .addCase(fetchBankAccounts.rejected, (state) => {
        state.bankAccounts = {
          data: [],
          status: ApiStatus.idle,
        };
      })
      .addCase(fetchPaymentReasons.pending, (state) => {
        state.reasons.status = ApiStatus.loading;
      })
      .addCase(fetchPaymentReasons.fulfilled, (state, action) => {
        state.reasons = {
          data: action.payload,
          status: ApiStatus.idle,
        };
      })
      .addCase(fetchPaymentReasons.rejected, (state) => {
        state.reasons = {
          data: [],
          status: ApiStatus.idle,
        };
      })
      .addCase(createTransaction.pending, (state) => {
        state.status = ApiStatus.loading;
      })
      .addCase(createTransaction.fulfilled, (state) => {
        state.status = ApiStatus.idle;
      })
      .addCase(createTransaction.rejected, (state) => {
        state.status = ApiStatus.idle;
      })
      .addCase(onUpdateTransaction.pending, (state) => {
        state.status = ApiStatus.loading;
      })
      .addCase(onUpdateTransaction.fulfilled, (state) => {
        state.status = ApiStatus.idle;
      })
      .addCase(onUpdateTransaction.rejected, (state) => {
        state.status = ApiStatus.idle;
      });
  },
});

export const {
  setDirection,
  setAmount,
  setFieldErrors,
  setHasScheduleTime,
  setFromBankAccount,
  setToBankAccount,
  setNotes,
  setMemo,
  setScheduleTime,
  setCustomRecurrence,
  setAmountType,
  setToDetails,
  setFromDetails,
  setCustomRecurrenceErrors,
  initScheduledEditMode,
  resetTransactionState,
  resetCustomRecurrence,
  resetState,
  setDescription,
  setFundsReturnDate,
  setAdditionalAmountType,
  setAdditionalAmount,
  setShowReturnFunds,
  setShowAdditionalAmount,
} = createTransactionSlice.actions;

export default createTransactionSlice.reducer;
