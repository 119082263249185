import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getRules } from 'services/RuleService';
import { resetStore } from 'store/actions';

import { ApiStatus, SortOrder } from 'constants/enums';
import { DEFAULT_PAGINATION_SIZE } from 'constants/general';
import { RulesManagementFilter } from 'models/filter.interface';
import { Pagination } from 'models/pagination.interface';
import { CreateRule } from 'models/rule.interface';
import { handleApiErrors } from 'utils/errorUtils';

interface SortOptionsState {
  name: string;
  type: SortOrder;
}

export interface RuleManagementState {
  rules: {
    data: CreateRule[];
    status: ApiStatus;
  };
  pagination: Pagination;
  search: string;
  filter: RulesManagementFilter;
  sortOptions: SortOptionsState;
  archived: boolean;
}

const initialState: RuleManagementState = {
  rules: {
    data: [],
    status: ApiStatus.idle,
  },
  pagination: {
    totalPages: 0,
    totalElements: 0,
    page: 1,
  },
  sortOptions: {
    name: 'name',
    type: SortOrder.desc,
  },
  search: '',
  filter: {
    status: null,
  },
  archived: false,
};

export const fetchRules = createAsyncThunk(
  'ruleHistory/fetchRules',
  async (
    params: { page: number; sort: string; workspaceId: string; search: string; filter: RulesManagementFilter },
    thunkAPI,
  ) => {
    try {
      const queryParams = {
        page: params.page,
        size: DEFAULT_PAGINATION_SIZE,
        sort: params.sort,
        search: params.search,
        filter: params.filter,
        ownerId: params.workspaceId,
      };
      const response = await getRules(queryParams);
      return response.data;
    } catch (e) {
      handleApiErrors(e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const ruleManagementSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    updateSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
      state.pagination.page = 1;
      return state;
    },
    updatePage: (state, action: PayloadAction<number>) => {
      state.pagination.page = action.payload;
      return state;
    },
    updateArchived: (state, action: PayloadAction<boolean>) => {
      state.archived = action.payload;
      return state;
    },
    updateSortOptions: (state, action: PayloadAction<SortOptionsState>) => {
      state.sortOptions = action.payload;
      return state;
    },
    updateFilter: (state, action: PayloadAction<RulesManagementFilter>) => {
      state.filter = action.payload;
      state.pagination.page = 1;
      return state;
    },
    resetState: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(resetStore, () => initialState)
      .addCase(fetchRules.pending, (state) => {
        state.rules = {
          ...state.rules,
          status: ApiStatus.loading,
        };
      })
      .addCase(fetchRules.fulfilled, (state, action) => {
        const { content, totalPages, totalElements } = action.payload || {};

        state.rules = {
          ...state.rules,
          status: ApiStatus.idle,
          data: content,
        };
        state.pagination = {
          ...state.pagination,
          totalPages,
          totalElements,
        };
      })
      .addCase(fetchRules.rejected, (state) => {
        state.rules = {
          ...state.rules,
          status: ApiStatus.idle,
        };
      });
  },
});

export const {
  resetState,
  updateSearch,
  updateFilter,
  updateArchived,
  updatePage,
  updateSortOptions,
} = ruleManagementSlice.actions;

export default ruleManagementSlice.reducer;
