import { zodResolver } from '@hookform/resolvers/zod/dist/zod';
import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import CompanyForm from 'components/CompanyForm';
import CustomButton from 'components/CustomButton';

import { useWorkspaceAuth } from 'hooks/useWorkspaceAuth';

import CloseIcon from 'assets/icons/CloseIcon';
import LogoText from 'assets/images/logo-text-white-beta.svg';
import { APP_NAME, GENERAL_TOAST_OPTIONS } from 'constants/general';
import { User } from 'models/user.interface';
import { handleApiErrors } from 'utils/errorUtils';
import {
  DummyCompanyFormValues,
  dummyCompanyFormSchema,
  WorkspaceFormValues,
} from 'utils/validation/workspaceFormSchema';

import {
  Background, CardMedia, Header, IconButton,
} from './index.styled';

interface CreateDummyCompanyProps {
  onSubmit: (values: DummyCompanyFormValues) => void;
  currentUser: User|null;
  onClose: () => void;
}

const CreateDummyCompany:FC<CreateDummyCompanyProps> = ({
  onSubmit,
  currentUser,
  onClose,
}) => {
  const intl = useIntl();
  const { createAndSwitchWorkspace } = useWorkspaceAuth();

  const {
    register,
    control,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<DummyCompanyFormValues>({
    resolver: zodResolver(dummyCompanyFormSchema),
    mode: 'onSubmit',
    defaultValues: {
      name: '',
      phoneNumber: currentUser?.phoneNumber || '',
    },
  });

  const handleCreateCompany = async (values: DummyCompanyFormValues) => {
    await createAndSwitchWorkspace(
      values,
      (newWorkspace: WorkspaceFormValues) => {
        toast.success(
          intl.formatMessage(
            { id: 'label.successfullySwitchedWorkspace' },
            { displayName: newWorkspace.displayName },
          ),
          GENERAL_TOAST_OPTIONS,
        );

        onSubmit(values);
      },
      (error) => {
        handleApiErrors<WorkspaceFormValues>(error, setError);
      },
    );
  };

  return (
    <>
      <Header>
        <CardMedia
          component="img"
          src={LogoText}
          alt="Primary account"
        />
        <Typography
          variant="label2"
          fontWeight={600}
          color="general.lightGreen5"
          sx={{ opacity: 0.9, marginLeft: '35px', display: 'block' }}
        >
          {intl.formatMessage({ id: 'label.centralizeYourAccounts' }, { appName: APP_NAME })}
        </Typography>
        <Background />
      </Header>

      <Box p={7}>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
          <Typography variant="h1">
            {intl.formatMessage({ id: 'label.newCompany' })}
          </Typography>

          <IconButton onClick={onClose} aria-label="close modal" id="iconButton-close-new-company">
            <CloseIcon />
          </IconButton>
        </Box>

        <CompanyForm
          control={control}
          register={register}
          errors={errors}
          onSubmit={handleSubmit(handleCreateCompany)}
        />

        <CustomButton
          fullWidth
          disabled={isSubmitting}
          onClick={handleSubmit(handleCreateCompany)}
          id="button-submitCreateCompany"
          label={intl.formatMessage({ id: 'button.create' })}
        />
      </Box>
    </>
  );
};

export default CreateDummyCompany;
