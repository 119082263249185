/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const ArchiveIcon = ({ color = 'currentColor', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3 3C2.448 3 2 3.448 2 4V6C2 6.552 2.448 7 3 7H21C21.552 7 22 6.552 22 6V4C22 3.448 21.552 3 21 3H3ZM4 9C3.448 9 3 9.448 3 10V19C3 20.105 3.895 21 5 21H19C20.105 21 21 20.105 21 19V10C21 9.448 20.552 9 20 9H4ZM10 11H14C14.552 11 15 11.448 15 12C15 12.552 14.552 13 14 13H10C9.448 13 9 12.552 9 12C9 11.448 9.448 11 10 11Z"
      fill={color}
    />
  </svg>
);

export default ArchiveIcon;
