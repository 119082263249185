import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getTransactionApprovalDetail } from 'services/TransactionApprovalService';
import { getPaymentReasons } from 'services/TransactionService';
import { resetStore } from 'store/actions';

import { ApiStatus } from 'constants/enums';
import { PaymentReasonInterface } from 'models/paymentReason.interface';
import { TransactionApproval } from 'models/transactionApproval.interface';
import { User } from 'models/user.interface';
import { handleApiErrors } from 'utils/errorUtils';

export interface TransactionState {
  details: {
    data?: TransactionApproval;
    status: ApiStatus;
  };
  createdBy: {
    data?: User;
    status: ApiStatus;
  };
  updatedBy: {
    data?: User;
    status: ApiStatus;
  };
  reasons: {
    data: PaymentReasonInterface[];
    status: ApiStatus;
  };
}

const initialState: TransactionState = {
  details: {
    data: undefined,
    status: ApiStatus.loading,
  },
  createdBy: {
    data: undefined,
    status: ApiStatus.idle,
  },
  updatedBy: {
    data: undefined,
    status: ApiStatus.idle,
  },
  reasons: {
    data: [],
    status: ApiStatus.idle,
  },
};

export const fetchTransactionDetails = createAsyncThunk(
  'transactionApprovalDetails/fetchTransactionDetails',
  async (transactionId: string, thunkAPI) => {
    try {
      const response = await getTransactionApprovalDetail(transactionId);

      if (response.data.createdByUserName) {
        thunkAPI.dispatch(setCreatedBy({ name: response.data.createdByUserName } as User));
      }

      if (response.data.updatedByUserName) {
        thunkAPI.dispatch(setUpdatedBy({ name: response.data.updatedByUserName } as User));
      }

      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const fetchPaymentReasons = createAsyncThunk(
  'transactionApprovalDetails/paymentReasons',
  async (_, thunkAPI) => {
    try {
      const { data } = await getPaymentReasons();
      return data;
    } catch (e) {
      handleApiErrors(e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const transactionApprovalDetailsSlice = createSlice({
  name: 'transactionApprovalDetails',
  initialState,
  reducers: {
    setCreatedBy: (state, action: PayloadAction<User>) => {
      state.createdBy = {
        ...state.createdBy,
        data: action.payload,
      };
    },
    setUpdatedBy: (state, action: PayloadAction<User>) => {
      state.updatedBy = {
        ...state.updatedBy,
        data: action.payload,
      };
    },
    resetState: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(resetStore, () => initialState)
      .addCase(fetchTransactionDetails.pending, (state) => {
        state.details = {
          ...state.details,
          status: ApiStatus.loading,
        };
      })
      .addCase(fetchTransactionDetails.fulfilled, (state, action) => {
        state.details = {
          ...state.details,
          data: action.payload,
          status: ApiStatus.idle,
        };
      })
      .addCase(fetchTransactionDetails.rejected, (state) => {
        state.details = {
          data: undefined,
          status: ApiStatus.idle,
        };
      })
      .addCase(fetchPaymentReasons.pending, (state) => {
        state.reasons.status = ApiStatus.loading;
      })
      .addCase(fetchPaymentReasons.fulfilled, (state, action) => {
        state.reasons = {
          data: action.payload,
          status: ApiStatus.idle,
        };
      })
      .addCase(fetchPaymentReasons.rejected, (state) => {
        state.reasons = {
          data: [],
          status: ApiStatus.idle,
        };
      });
  },
});

export const {
  resetState,
  setCreatedBy,
  setUpdatedBy,
} = transactionApprovalDetailsSlice.actions;

export default transactionApprovalDetailsSlice.reducer;
