import { Box, Typography } from '@mui/material';
import { FC, ReactElement } from 'react';
import { useIntl } from 'react-intl';

import Logo from 'components/Logo';
import User from 'layouts/PortalLayout/User';

import { ADMIN_PORTAL, MINI_ADMIN_PORTAL } from 'constants/general';
import { SidebarInterface } from 'models/sidebar.interface';

import {
  LinkWrapper,
  NavbarLink,
  Drawer,
  DrawerContent,
  PortalBox,
} from './index.styled';

interface SidebarProps {
  sidebarItems: SidebarInterface[];
  isMini?: boolean;
}

const Sidebar: FC<SidebarProps> = ({ sidebarItems, isMini }): ReactElement => {
  const intl = useIntl();

  return (
    <Drawer open variant="permanent" isMini={isMini}>
      <DrawerContent isMini={isMini}>
        <Logo withText={!isMini} />
        <PortalBox>
          <Typography variant="label2">
            {isMini ? MINI_ADMIN_PORTAL : ADMIN_PORTAL}
          </Typography>
        </PortalBox>

        <Box width="100%" display="flex" flexDirection="column" flexGrow={1} gap={1} mt={4}>
          {sidebarItems.map(({
            route, key, label, Icon,
          }): ReactElement => (
            <NavbarLink to={route} key={key}>
              {({ isActive }): ReactElement => (
                <LinkWrapper isActive={isActive}>
                  {Icon && <Icon size={24} />}

                  {!isMini && (
                    <Typography variant="body2">
                      {intl.formatMessage({ id: label })}
                    </Typography>
                  )}
                </LinkWrapper>
              )}
            </NavbarLink>
          ))}
        </Box>

        <Box mb={10}>
          <User isMini={isMini} />
        </Box>
      </DrawerContent>
    </Drawer>
  );
};

export default Sidebar;
