import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getSubscription, saveSubscription, updateSubscription } from 'services/SubscriptionService';
import { resetStore } from 'store/actions';
import { RootState } from 'store/index';

import { ApiStatus, PaymentType, SubscriptionType } from 'constants/enums';
import { BankAccountPaymentDTO, CardPaymentDTO } from 'models/payment.interface';
import { Subscription, SubscriptionPayload } from 'models/subscriptionPayment.interface';

export interface SubscriptionFormInfo {
  bankAccount: Partial<BankAccountPaymentDTO> | null;
  card: Partial<CardPaymentDTO> | null;
  type: SubscriptionType | null;
  agreementAccepted: boolean;
  paymentType: PaymentType;
}

export interface SubscriptionState {
  subscription: Subscription|null;
  status: ApiStatus;
  form: SubscriptionFormInfo;
}

const initialState: SubscriptionState = {
  subscription: null,
  status: ApiStatus.idle,
  form: {
    bankAccount: null,
    card: null,
    type: null,
    agreementAccepted: false,
    paymentType: PaymentType.bank,
  },
};

export const fetchSubscription = createAsyncThunk(
  'subscription/fetchSubscription',
  async (_, thunkAPI) => {
    try {
      const { data } = await getSubscription();

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const saveSubscriptionInformation = createAsyncThunk(
  'subscription/saveSubscriptionInformation',
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const { subscription: { form } } = state;
      const payload = {
        type: form.type,
        agreementAccepted: form.agreementAccepted,
      } as SubscriptionPayload;

      if (form.agreementAccepted && (form.card || form.bankAccount)) {
        payload.paymentInfo = form.card || form.bankAccount;
      }

      await saveSubscription(payload);
      const { data } = await getSubscription();

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const updateSubscriptionInfo = createAsyncThunk(
  'subscription/updateSubscriptionInfo',
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const { subscription: { form, subscription } } = state;
      const payload = {
        ...(subscription || {}),
      } as SubscriptionPayload;

      if (form.agreementAccepted) {
        payload.agreementAccepted = true;
      }

      if ((form.agreementAccepted || subscription?.agreementAccepted) && (form.card || form.bankAccount)) {
        payload.paymentInfo = form.card || form.bankAccount;
      }

      await updateSubscription(payload);
      const { data } = await getSubscription();

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const updateSubscriptionPlan = createAsyncThunk(
  'subscription/updateSubscriptionPlan',
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const { subscription: { form, subscription } } = state;
      const payload = {
        ...(subscription || {}),
        type: form.type,
        paymentInfo: null,
      } as SubscriptionPayload;

      await updateSubscription(payload);
      const { data } = await getSubscription();

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const subscriptionSlice = createSlice({
  name: 'loggedUser',
  initialState,
  reducers: {
    setSubscriptionType: (state, action: PayloadAction<SubscriptionType>) => {
      state.form = {
        ...state.form,
        type: action.payload,
      };
      return state;
    },
    setPaymentType: (state, action: PayloadAction<PaymentType>) => {
      state.form = {
        ...state.form,
        paymentType: action.payload,
      };
      return state;
    },
    setAgreement: (state, action: PayloadAction<boolean>) => {
      state.form = {
        ...state.form,
        agreementAccepted: action.payload,
      };
      return state;
    },
    setPaymentDetails: (state, action: PayloadAction<Partial<SubscriptionFormInfo>>) => {
      state.form = {
        ...state.form,
        ...action.payload,
      };
      return state;
    },
    resetFormState: (state) => {
      state.form = initialState.form;
      return state;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(resetStore, () => initialState)
      .addCase(fetchSubscription.pending, (state) => {
        state.status = ApiStatus.loading;
      })
      .addCase(fetchSubscription.fulfilled, (state, action) => {
        state.status = ApiStatus.idle;
        state.subscription = action.payload;
      })
      .addCase(fetchSubscription.rejected, (state) => {
        state.status = ApiStatus.idle;
      })
      .addCase(saveSubscriptionInformation.pending, (state) => {
        state.status = ApiStatus.loading;
      })
      .addCase(saveSubscriptionInformation.fulfilled, (state, action) => {
        state.status = ApiStatus.idle;
        state.subscription = action.payload;
      })
      .addCase(saveSubscriptionInformation.rejected, (state) => {
        state.status = ApiStatus.idle;
      })
      .addCase(updateSubscriptionInfo.pending, (state) => {
        state.status = ApiStatus.loading;
      })
      .addCase(updateSubscriptionInfo.fulfilled, (state, action) => {
        state.status = ApiStatus.idle;
        state.subscription = action.payload;
      })
      .addCase(updateSubscriptionInfo.rejected, (state) => {
        state.status = ApiStatus.idle;
      })
      .addCase(updateSubscriptionPlan.pending, (state) => {
        state.status = ApiStatus.loading;
      })
      .addCase(updateSubscriptionPlan.fulfilled, (state, action) => {
        state.status = ApiStatus.idle;
        state.subscription = action.payload;
      })
      .addCase(updateSubscriptionPlan.rejected, (state) => {
        state.status = ApiStatus.idle;
      });
  },
});

export const {
  setSubscriptionType,
  setAgreement,
  setPaymentType,
  setPaymentDetails,
  resetFormState,
} = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
