import { isNil } from 'lodash';

import {
  RECIPIENT_TRANSACTION_DETAILS_ROUTE,
  SCHEDULED_TRANSACTION_DETAILS_ROUTE,
  TRANSACTION_DETAILS_TYPE_ROUTE,
} from 'constants/clientRoutes';
import {
  DateFormats, DefaultType, KorTransactionType, TransactionType,
} from 'constants/enums';
import { ScheduledTransaction, TransactionDTO } from 'models/transaction.interface';
import { convertTransactionFaToAccount, isMLT } from 'utils/createTransaction';
import { formatDate, formatRoute } from 'utils/formatters';
import { ActivityTransactionFilterSchema, TransactionFilterSchema } from 'utils/validation/transactionFormSchema';

export const formatTransactionFilters = (filters: TransactionFilterSchema) => {
  const {
    types, dateRange, amountRange,
  } = filters;
  const trnTypes: TransactionType[] = [];

  if (types?.inflow) {
    trnTypes.push(TransactionType.credit);
  }

  if (types?.outflow) {
    trnTypes.push(TransactionType.debit);
  }

  return {
    ...(trnTypes.length > 0 ? { trnTypes: trnTypes.join(',') } : {}),
    ...(dateRange?.from ? { startDate: dateRange?.from } : {}),
    ...(dateRange?.to ? { endDate: dateRange?.to } : {}),
    ...(amountRange?.min ? { minAmount: +amountRange?.min } : {}),
    ...(amountRange?.max ? { maxAmount: +amountRange?.max } : {}),
  };
};

interface TransactionsSearchQueryParams {
  'bank-account-id': string;
  page: number;
  size: number;
  sort?: string;
  search?: string;
}

export const formatQueryParamsAsSearchParams = (filters: TransactionsSearchQueryParams) => {
  const searchParams: URLSearchParams = new URLSearchParams();
  (Object.keys(filters) as (keyof typeof filters)[]).forEach((key) => {
    const filterValue = filters[key];
    if (!isNil(filterValue)) {
      searchParams.set(key, filterValue.toString());
    }
  });

  if (filters.sort && filters.sort === 'postedDate,desc') {
    searchParams.append('sort', 'idpTransactionId,desc');
  }

  return searchParams;
};

export const formatSearchTransactionFilters = (filters: TransactionFilterSchema) => {
  const {
    types,
    dateRange,
    amountRange,
    banks,
  } = filters;
  const trnTypes: TransactionType[] = [];
  const bankCodes: string[] = [];

  if (types?.inflow) {
    trnTypes.push(TransactionType.credit);
  }

  if (types?.outflow) {
    trnTypes.push(TransactionType.debit);
  }

  if (types?.send) {
    trnTypes.push(TransactionType.send);
  }

  if (types?.request) {
    trnTypes.push(TransactionType.request);
  }

  if (banks) {
    Object.entries(banks).forEach(([bankCode, checked]) => {
      if (checked && bankCode !== DefaultType.all) {
        bankCodes.push(bankCode);
      }
    });
  }

  return {
    ...(trnTypes.length > 0 ? { transactionTypes: trnTypes.join(',') } : {}),
    ...(dateRange?.from ? { dateFrom: formatDate(dateRange?.from, DateFormats.transactionDate) } : {}),
    ...(dateRange?.to ? { dateTo: formatDate(dateRange?.to, DateFormats.transactionDate) } : {}),
    ...(amountRange?.min ? { amountFrom: +amountRange?.min } : {}),
    ...(amountRange?.max ? { amountTo: +amountRange?.max } : {}),
    ...(bankCodes.length > 0 ? { bankCodes: bankCodes.join(',') } : {}),
  };
};

export const getTransactionPriceColor = (trnType: TransactionType) => {
  if (trnType === TransactionType.debit) {
    return 'error.main';
  }

  if (trnType === TransactionType.credit) {
    return 'success.main';
  }

  return 'textPrimary';
};

export const formatActivityTransactionFilters = (filters: ActivityTransactionFilterSchema) => {
  const {
    latestStatus,
    dateRange,
    amountRange,
  } = filters;

  return {
    ...(latestStatus !== DefaultType.all ? { latestStatus } : {}),
    ...(dateRange?.from ? { createdDateFrom: formatDate(dateRange?.from, DateFormats.transactionDate) } : {}),
    ...(dateRange?.to ? { createdDateTo: formatDate(dateRange?.to, DateFormats.transactionDate) } : {}),
    ...(amountRange?.min ? { amountFrom: +amountRange?.min } : {}),
    ...(amountRange?.max ? { amountTo: +amountRange?.max } : {}),
  };
};

export const formatTransactionDetailsRoute = (
  transaction: TransactionDTO|ScheduledTransaction,
  isScheduled?: boolean,
) => {
  const type = transaction.type || isMLT(
    convertTransactionFaToAccount(transaction.transactionFaFrom),
    convertTransactionFaToAccount(transaction.transactionFaTo),
  );

  return formatRoute(
    isScheduled ? SCHEDULED_TRANSACTION_DETAILS_ROUTE : TRANSACTION_DETAILS_TYPE_ROUTE,
    {
      transactionId: transaction.id,
      type,
    },
  );
};

export const formatRecipientTransactionDetailsRoute = (transaction: TransactionDTO, recipientId: string) => {
  const mltTransaction = isMLT(
    convertTransactionFaToAccount(transaction.transactionFaFrom),
    convertTransactionFaToAccount(transaction.transactionFaTo),
  );

  return formatRoute(
    RECIPIENT_TRANSACTION_DETAILS_ROUTE,
    {
      recipientId,
      transactionId: transaction.id,
      type: (mltTransaction ? KorTransactionType.mlt : KorTransactionType.slt).toLowerCase(),
    },
  );
};
